import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
// import PartnerSwiper from "../components/swiper/swiper-partner.js"
import { RiAlarmWarningLine } from "react-icons/ri"
import { RiFileSettingsLine } from "react-icons/ri"
import { RiLightbulbLine } from "react-icons/ri"
import { RiTeamFill } from "react-icons/ri"
// import Cases from "../components/cases"
import Banner from "../components/hero/hero-healthcare.js"
// import Testimonials from "../components/swiper/testimonials-new"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const Modal = loadable(() => import("../components/modal/modal-patient-care"))

const UnlockEnterprise = loadable(() =>
  pMinDelay(import("../components/unlock-enterprise"), 500)
)

const Testimonials = loadable(() =>
  pMinDelay(import("../components/swiper/testimonials-new"), 500)
)

const Cases = loadable(() => pMinDelay(import("../components/cases"), 500))

class HealthCarePage extends React.Component {
  render() {
    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="Healthcare | Quandary Consulting Group"
          description="We build secure, HIPAA-compliant applications and integrations that give you the time and data needed to improve patient care."
        />

        <LazyLoadComponent>
          <section
            className="quickbase-integrations"
            style={{ padding: "5rem 0" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="h3 text-white mt-4">
                    CUSTOM HEALTHCARE APPLICATIONS AND INTEGRATIONS FOR PATIENT
                    CARE
                  </h2>
                  <p className="text-white">
                    Custom low-code applications and integrations designed for
                    every aspect of healthcare. Built in weeks. Not months. Or
                    years.
                  </p>
                </div>

                <div className="col-lg-4 col-md-6 mt-5 text-center">
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid rounded"
                    src="../images/automated-physician.png"
                    alt="Automated Physician Billing"
                    layout="constrained"
                    style={{ width: "25%" }}
                    loading="lazy"
                  />
                  <p className="my-4 text-white font-weight-bold text-uppercase">
                    Automated Physician Billing
                  </p>
                  <p className="pt-2 text-white">
                    Track physician engagements, create bill logs, predict
                    future billings, and manage invoices for automated billing.
                  </p>
                </div>
                <div className="col-lg-4 col-md-6 mt-5 text-center">
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid rounded"
                    src="../images/streamline-patient.png"
                    alt="Streamline Patient Management"
                    layout="constrained"
                    style={{ width: "25%" }}
                    loading="lazy"
                  />
                  <p className="my-4 text-white font-weight-bold text-uppercase">
                    Streamline Patient Management
                  </p>
                  <p className="pt-2 text-white">
                    Automate patient care and communications with integrated
                    systems that keep your critical data secure and HIPAA
                    compliant.
                  </p>
                </div>
                <div className="col-lg-4 col-md-6 mt-5 text-center">
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid rounded"
                    src="../images/physician-workforce.png"
                    alt="Physician Workforce Management"
                    layout="constrained"
                    style={{ width: "25%" }}
                    loading="lazy"
                  />
                  <p className="my-4 text-white font-weight-bold text-uppercase">
                    Physician Workforce Management
                  </p>
                  <p className="pt-2 text-white">
                    Track physician hours, rates, and shifts worked across a
                    vast hospital network automatically for more accurate
                    insurance billing.
                  </p>
                </div>
                <div className="col-lg-4 col-md-6 mt-5 text-center">
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid rounded"
                    src="../images/workflow.png"
                    alt="Workflow Process Optimization"
                    layout="constrained"
                    style={{ width: "25%" }}
                    loading="lazy"
                  />
                  <p className="my-4 text-white font-weight-bold text-uppercase">
                    Workflow Process Optimization
                  </p>
                  <p className="pt-2 text-white">
                    Streamline how work gets done in your hospitals by removing
                    inefficiencies, roadblocks, and redundancies to create
                    optimal workflows.
                  </p>
                </div>
                <div className="col-lg-4 col-md-6 mt-5 text-center">
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid rounded"
                    src="../images/improve-patient.png"
                    alt="Improve Patient Care"
                    layout="constrained"
                    style={{ width: "25%" }}
                    loading="lazy"
                  />
                  <p className="my-4 text-white font-weight-bold text-uppercase">
                    Improve Patient Care
                  </p>
                  <p className="pt-2 text-white">
                    Empower your physicians to make informed decisions on
                    patient care using data from a single, accessible dashboard.
                  </p>
                </div>
                <div className="col-lg-4 col-md-6 mt-5 text-center">
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid rounded"
                    src="../images/aggregate-patient.png"
                    alt="Aggregate Patient Data"
                    layout="constrained"
                    style={{ width: "25%" }}
                    loading="lazy"
                  />
                  <p className="my-4 text-white font-weight-bold text-uppercase">
                    Aggregate Patient Data
                  </p>
                  <p className="pt-2 text-white">
                    Collect and anonymize patient data across numerous tools and
                    interactions to support retrospective studies to improve
                    patient care.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </LazyLoadComponent>

        <LazyLoadComponent>
          <section className="what-to-expect" style={{ padding: "5rem 0" }}>
            <div className="container pb-5">
              <div className="row">
                <div className="col-lg-12 col-md-8 mx-auto text-center">
                  <h2 className="font-weight-medium">WE KNOW HEALTHCARE.</h2>
                  <p className="mt-3">
                    We work with you to ensure your custom applications meet the
                    needs of your physicians, clinical support staff, and
                    patients. Across your network.
                  </p>
                </div>
              </div>
              <div className="row" style={{ padding: "65px 0px 0px 0px" }}>
                <div
                  className="col mb-4 mx-5 what-to-expext-box text-center"
                  style={{ borderRadius: "5px" }}
                >
                  <p className="text-green pt-3 font-weight-bold">DISCOVERY</p>
                  <p className="my-3 pb-3">
                    We understand your business first. Digging deep to uncover
                    the source of the problem.
                  </p>
                </div>
                <div
                  className="col mb-4 mx-5 what-to-expext-box text-center"
                  style={{ borderRadius: "5px" }}
                >
                  <p className="text-green pt-3 font-weight-bold">
                    DEVELOPMENT
                  </p>
                  <p className="my-3 pb-3">
                    We build high-quality, custom applications quickly using
                    low-code tools in weeks (not 12-18 months).
                  </p>
                </div>
                <div
                  className="col mb-4 mx-5 what-to-expext-box text-center"
                  style={{ borderRadius: "5px" }}
                >
                  <p className="text-green pt-3 font-weight-bold">DEPLOYMENT</p>
                  <p className="my-3 pb-3">
                    We launch the new tools and support your team while sharing
                    opportunities we uncovered for future growth.
                  </p>
                </div>
              </div>
              <div className="col-lg-8 mx-auto text-center pt-5">
                <Modal />
              </div>
            </div>
          </section>
        </LazyLoadComponent>

        <LazyLoadComponent>
          <section id="case-studies-container">
            <div id="case-studies" className="bg-indigo">
              <div className="container-fluid">
                <div className="intro text-center">
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <p
                        className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                        style={{ fontFamily: "Futura,sans-serif" }}
                      >
                        Case Studies
                      </p>
                      <h2 className=" my-2 text-white">
                        We Save Clients 1000s of Hours. Every Year.
                      </h2>
                      {/* <p className="text-white">
                          We helped a national healthcare provider save over
                          9,000 hours in weekly labor costs by developing a
                          single application...
                        </p> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <Suspense fallback={<div>Loading...</div>}> */}
              <div className="">
                <Cases
                  allSanityCaseStudy={this.props.data.allSanityCaseStudy}
                />
              </div>
              {/* </Suspense> */}
              <div className="text-center">
                <Link
                  className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
                  to="/case-studies/"
                >
                  More Case Studies
                </Link>
              </div>
            </div>
          </section>
        </LazyLoadComponent>

        <LazyLoadComponent>
          <section id="case-studies-container">
            <div id="case-studies">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto text-center">
                    <h2>
                      Automate Patient Care: Secure, Compliant, Accessible
                    </h2>
                    <p>
                      Get HIPPA-compliant automations, applications, and
                      integrations that improve patient processing and care.
                    </p>

                    <div className="relative">
                      <Modal />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </LazyLoadComponent>

        <UnlockEnterprise />

        <Testimonials />
      </Layout>
    )
  }
}

export default HealthCarePage

export const healthCarePageQuery = graphql`
  query healthCarePageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
